import { combineReducers } from "redux";
import authReducer from "./auth";
import partnerReducer from "./partner";
import userReducer from "./user";
import roleReducer from "./role";

export default combineReducers({
  auth: authReducer,
  partner: partnerReducer,
  user: userReducer,
  role: roleReducer
});
