import Select from 'react-select';
import { useFormikContext } from "formik";
import { BiSearch } from 'react-icons/bi';

export const AutoCompleteFieldComponent = (props: any) => {
  const { field, errors } = props;
  const { setFieldValue, values } = useFormikContext<any>();
  return (
    <>
      <div className="d-flex align-items-center">
        <Select
          value={field?.value}
          isSearchable
          isMulti
          name="colors"
          placeholder={field.placeholder}
          options={field?.options || []}
          className="basic-multi-select w-100"
          classNamePrefix="select"
          onInputChange={(val: string, meta: any) => { meta.action === "input-change" && setFieldValue(field.name, val) }}
          onChange={(newValue: any) => field.onChange(newValue)}
        />
        <div className="ml-2 btn-primary sharp btn-sm" onClick={() => field.onSearch(values[field.name])}>
          <BiSearch />
        </div>
      </div>
      {errors.hasOwnProperty(field.name) && (
        <div className="invalid-feedback errorFieldMessage">
          {errors[field.name]}
        </div>
      )}
    </>
  );
}