// import PerfectScrollbar from "react-perfect-scrollbar";
import * as MenuIcons from "react-icons/all";
import { IMenu } from "@shared/constants";
import { useSelector } from "react-redux";
import { getUi } from "@store/ui";

import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom'

const SideBar = ({
  menus,
  setSelectedMenu,
}: {
  menus: IMenu[];
  setSelectedMenu: any;
}) => {
  const { ui } = useSelector(getUi);
  const [listMenus, setListMenus] = useState<IMenu[]>([]);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setListMenus(menus);
    }, 150);
  }, [menus]);

  const activeClass = (subMenu: any) => {
    if (location) {
      const url_location = location.pathname + location.search;
      if (url_location === subMenu.url) {
        return "active"
      }
      else {
        return "non-active"
      }
    }
    else {
      return "non-active";
    }
  }

  return (
    <>
      <div className="deznav">
        <div className="wrap-sidebar">
          <div className={`${ui.isSidebarOpen ? "open" : ""}`}>
            <ProSidebar>
              {listMenus.map((menu, i) => {
                const icon: keyof typeof MenuIcons = menu.icon;
                const MenuIcon = MenuIcons[icon];
                return (
                  <Menu iconShape="circle" key={i}>
                    {menu.subMenus ? (
                      <SubMenu title={menu.title} icon={<MenuIcon />}>
                        {menu.subMenus.map((subMenu, i) => {
                          //Validation Organization_Type ca or partner
                          return (
                            <MenuItem
                              key={i}
                              onClick={() => setSelectedMenu(subMenu)}
                            >
                              <Link className={activeClass(subMenu)} to={subMenu.url}>{subMenu.title}</Link>
                            </MenuItem>
                          )
                        })
                        }
                      </SubMenu>
                    ) : (
                      <MenuItem
                        icon={<MenuIcon />}
                        onClick={() => setSelectedMenu(menu)}
                      >
                        <Link className={activeClass(menu)} to={menu.url}>{menu.title}</Link>
                      </MenuItem>
                    )}
                  </Menu>
                );
              })}
            </ProSidebar>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideBar;
