import { REPORTING } from "@shared/constants";
import * as Yup from "yup";

export const searchFormValidations = (module: string) => {
  if (module === REPORTING) {
    return Yup.object().shape({
      transaction_date: Yup.string().required("Transaction date is required"),
    });
  }
  else {
    return null;
  }
}

export const changePasswordValidation = () => {
  return Yup.object().shape({
    new_password : Yup.string().oneOf([Yup.ref('confirm_new_password'), null], 'new Passwords must match'),
    confirm_new_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'new Passwords must match')
  })
}