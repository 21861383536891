import HomePage from "@pages/home.page";
import LoginPage from "@pages/login.pages";
import ProfilePage from "@pages/profile.page";
import { monitoringRoute } from "./router/monitoring.route";
import { partnerRoute } from "./router/partner.route";
import { reportingRoute } from "./router/reporting.route";
import { userRoute } from "./router/user.route";

export const routes = [
  {
    path: "/",
    element: <HomePage />,
    isAuth: true,
  },
  {
    path: "/login-page",
    element: <LoginPage />,
    isAuth: false,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
    isAuth: true,
  },
  ...userRoute,
  // ...partnerRoute,
  ...monitoringRoute,
  ...reportingRoute,
];