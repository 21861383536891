import { Button, InputField } from '@components/common';
import { api, createRequest } from '@shared/api';
import { api_url } from '@shared/constants';
import { formRequestUser } from '@shared/constants/form/user.form.constant';
import { userFormReqValidation } from '@shared/validations/user.validation';
import { Form, Formik } from 'formik';
import React, { Key, useState } from 'react'
import { Alert, Card, Col, Row } from 'react-bootstrap'
import { initialValueUserRequest } from '../dtos/user.dto';

export const UserRequestComponent = (props: any) => {
  const { auth } = props;
  const [formData, setFormData] = useState(formRequestUser);
  const [message, setMessage] = useState("");
  const [isSucceed, setIsSucceed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    setIsLoading(true);
    const res = await createRequest(e);
    if (res.status === 200) {
      setIsSucceed(true);
      setMessage(res.data.message);
      setIsLoading(false);
    } else {
      setIsSucceed(false);
      setMessage(res.data.message);
      setIsLoading(false);
    }
  }

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header><h2>Request User</h2></Card.Header>
            <Card.Body>
              <Formik initialValues={initialValueUserRequest(null)} onSubmit={handleSubmit} validationSchema={userFormReqValidation}>
                {({ errors }) => {
                  return (
                    <Form>
                      <div className="form-row">
                        {formData.map((data: any, i: Key) => (
                          <InputField
                            field={data}
                            errors={errors}
                            key={i}
                            width={8}
                          />
                        ))}
                      </div>
                      <Button disabled={isLoading}>Submit</Button>
                      {message.length > 0 &&
                        <div style={{ maxWidth: "50%" }} className="mt-4">
                          <Alert variant={isSucceed === true ? "success" : "danger"} style={{ width: "fit-content" }}>{message}</Alert>
                        </div>
                      }
                    </Form>
                  )
                }}
              </Formik>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}
