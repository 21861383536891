import { SUPER_ROLE } from "@shared/constants";
import { Navigate } from "react-router-dom";

export const checkAuth = (isAuth: boolean, auth: any) => {
  const { access_token } = auth;

  if (access_token) {
    // const { name: role } = auth.data.UserRole[0];
    // const token = JSON.parse(atob(access_token.split('.')[1]));
    // if (SUPER_ROLE.includes(role)) return true; NOT USED CODE WAIT FOR THE REVIEW IN 1 MONTH
    if (isAuth) return true;
    return false;
  } else {
    return false;
  }
};

export const getElement = (data: any, auth: any) => {
  return checkAuth(data?.isAuth, auth) === true ? (
    data.element
  ) : (
    <Navigate to="/login-page" />
  );
};

export const randomPassword = () => Math.random().toString(36).slice(2) + Math.random().toString(36).slice(2);