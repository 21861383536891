import { UserRequestComponent } from '@components/module/user/request/user-request.component';
import UnauthorizedPage from '@pages/404.page';
import { Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';

const UserRequestPage = () => {
  const { auth } = useSelector(getAuthData);
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />;

  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
      case Role?.USER_ADMIN_CA:
      case Role?.USER_ADMIN_PARTNER:
      case Role?.USER_ADMIN_ALGO:
        renderPage = <UserRequestComponent auth={auth} />
        break;
      default:
        break;
    }
  }

  return <>{renderPage}</>
}

export default UserRequestPage
