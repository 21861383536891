import { deactivateUser, getUser, updateUser } from '@store/entities/user';
import React, { useEffect, useMemo, useState } from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import DataTable from 'react-data-table-component';
import { useDispatch, useSelector } from 'react-redux'
import { columnDetailUser } from '../data-table/data-table.component';
import { BackButton, Button, DeleteModalComponent, ModalFormComponent } from '@components/common';
import { initialValueUser } from '../dtos/user.dto';
import { mappingFormRole, removeEmptyObject } from '@shared/functions';
import { getRoleList } from '@store/entities/role';
import { formUserCreate, formUserUpdate } from '@shared/constants/form/user.form.constant';
import { fetchProductList } from '@shared/api';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { RES_STATUS } from '@shared/constants';

export const UserDetailComponent = (props: any) => {
  const { onLoading, userId } = props;
  const { user } = useSelector(getUser);
  const { userDetail } = user;
  const { roleList } = useSelector(getRoleList);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [formDataUpdate, setFormDataUpdate] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [productList, setProductList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [data, setData] = useState<any>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userDetail.products) {
      const data = userDetail?.products?.map((data: any) => { return { value: data.product_id, label: data.product_name, provider_id: data.provider_id } }) || [];
      setSelectedProduct(data);
    } else {
      setSelectedProduct([]);
    }
  }, [userDetail]);

  useEffect(() => {
    const formProduct = {
      name: "product_name",
      type: "autocomplete",
      label: "Product Name",
      placeholder: "Search Product Name",
      options: productList,
      onSearch: (val: any) => handleSearchProduct(val),
      onChange: (newValue: any) => setSelectedProduct(newValue),
      value: selectedProduct,
    }
    const removeEmailPassword = formUserCreate.filter((data: any) => data.name !== "email" && data.name !== "password");
    const dataUpdate = {
      name: "email",
      type: "text",
      label: "Email",
      placeholder: "Email",
      isActive: true,
    };
    const newArr = [dataUpdate, ...formUserUpdate, ...removeEmailPassword, formProduct]
    setFormDataUpdate(mappingFormRole(newArr, roleList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleList, productList, selectedProduct]);

  const tableContent = useMemo(() => (
    [
      {
        label: "Email",
        value: userDetail?.email
      },
      {
        label: "Role",
        value: userDetail?.roles?.map((data: any) => data.name),
      },
      {
        label: "Status",
        value: userDetail?.status
      },
    ]
  ), [userDetail]);

  const handleUpdate = () => {
    setShowModal(!showModal);
    const body = {
      ...userDetail,
      role_id: userDetail?.roles.find((el: any) => el.id)?.id,
    }
    setData(body);
  }

  const handleSubmit = (e: any, type?: string) => {
    if (type === "delete") {
      const body = {
        user_name: e.email
      }
      dispatch(deactivateUser(body));
    } else {
      const arrProducts = selectedProduct.map((data: any) => { return { product_id: data.value, provider_id: data.provider_id } });
      const newData = removeEmptyObject(e);
      const body = {
        name: newData.name,
        status: newData.status,
        role_id: parseInt(newData.role_id),
        product_provider: arrProducts,
        id: userId,
      }
      dispatch(updateUser(body));
    }

    if (user.status === RES_STATUS.SUCCEEDED) {
      setTimeout(() => {
        onLoading();
        if (type) {
          setShowDeleteModal(!showDeleteModal);
        } else {
          setShowModal(!showModal);
        }
        setErrorMessage("");
      }, 2000);
    } else {
      onLoading();
      setErrorMessage("Submit user failed!");
    }
  }

  const handleSearchProduct = async (val:any) => {
    if (val.length >= 3) {
      const params = {
        product_name: val,
        limit: 20,
        page: 1,
      };
      const res = await fetchProductList(params);
      if (res.status === 200 && res.data.data !== null) {
        const data = res.data.data.map((data: any) => { return { value: data.product_id, label: data.product_name, provider_id: data.provider_id } })
        setProductList(data);
      } else {
        setProductList([]);
      }
    }
  }

  return (
    <>
      <Row>
        <Col sm={12}>
          <Card>
            <Card.Header>
              <div className="d-flex align-content-center">
                <BackButton />
                <h2 className="m-0">User Detail</h2>
              </div>
              <div>
                <Button mode="tertiary" style={{ marginRight: "0.5rem" }} onClick={() => handleUpdate()}>Update</Button>
                <Button mode="danger" onClick={() => setShowDeleteModal(!showDeleteModal)}>
                  <RiDeleteBin2Fill />
                  Deactivate
                </Button>
              </div>
            </Card.Header>
            <Card.Body>
              <table className="table table-hover">
                <tbody>
                  {tableContent.map((data: any, i: number) => (
                    <tr key={i}>
                      <td>{data.label}</td>
                      <td>{data.value}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12}>
          <Card>
            <Card.Header><Card.Title>Product Table</Card.Title></Card.Header>
            <Card.Body>
              <DataTable
                columns={columnDetailUser}
                data={userDetail?.products || []}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <ModalFormComponent
        show={showModal}
        onHide={() => setShowModal(!showModal)}
        title={"Update User"}
        onSubmit={(e: any) => handleSubmit(e)}
        initialValue={initialValueUser(data)}
        formData={formDataUpdate}
        alertMessage={errorMessage}
      />

      <DeleteModalComponent
        onSubmit={() => handleSubmit(userDetail, "delete")}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(!showDeleteModal)}
        alertMessage={`Email ${userDetail?.email || "Data ini"} akan dihapus`}
      />
    </>
  )
}