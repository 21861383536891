import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { FaRegUser, FiLogOut, FiMail } from "react-icons/all";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { assemblyRole, getFirstWord } from "@shared/functions";

import { fetchLogout, getAuthData } from "@store/entities/auth";
import { IconContext } from "react-icons";

const Header = ({ title }: any) => {
  const dispatch = useDispatch();
  const { auth } = useSelector(getAuthData);
  const navigate = useNavigate();

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {/* {!title
                  ? getTitlePage()
                  : title === "Home"
                    ? "Dashboard"
                    : title} */}
              </div>
            </div>

            <ul className="navbar-nav header-right">

              {/* <Dropdown as="li" className="nav-item  notification_dropdown">
                <Dropdown.Toggle
                  variant=""
                  className="nav-link  ai-icon i-false"
                >
                  <AiOutlineBell className="bell-notification" />
                  <span className="badge light text-white bg-primary rounded-circle">
                    12
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="mt-2">
                  <PerfectScrollbar className="widget-media dz-scroll p-3 height380 overflow-auto min-w300 ps">
                    <ul className="timeline">
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2">
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">Dr sultads Send you Photo</h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2 media-info">KG</div>
                          <div className="media-body">
                            <h6 className="mb-1">
                              Resport created successfully
                            </h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="timeline-panel">
                          <div className="media mr-2 media-success">
                            <i className="fa fa-home" />
                          </div>
                          <div className="media-body">
                            <h6 className="mb-1">Reminder : Treatment Time!</h6>
                            <small className="d-block">
                              29 July 2020 - 02:26 PM
                            </small>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </PerfectScrollbar>
                  <Link className="all-notification" to="#">
                    See all notifications <i className="ti-arrow-right" />
                  </Link>
                </Dropdown.Menu>
              </Dropdown> */}

              <Dropdown as="li" className="nav-item header-profile ">
                <Dropdown.Toggle
                  as="a"
                  variant=""
                  className="nav-link i-false c-pointer"
                >
                  <div className="header-info">
                    <span className="text-black">
                      Hello, <strong>{getFirstWord(auth?.Username)}</strong>
                    </span>
                    <p className="fs-12 mb-0">
                      {assemblyRole(auth?.UserRole[0].name)}
                    </p>
                  </div>
                  <IconContext.Provider value={{ color: "#00CFFF" }}>
                    <div>
                      <FaRegUser />
                    </div>
                  </IconContext.Provider>
                </Dropdown.Toggle>
                <Dropdown.Menu className="mt-2">
                  <Link to="/profile" className="dropdown-item ai-icon">
                    <FaRegUser />
                    <span className="ml-2">Profile </span>
                  </Link>
                  <Link to="/email-inbox" className="dropdown-item ai-icon">
                    <FiMail />
                    <span className="ml-2">Inbox </span>
                  </Link>
                  <div
                    style={{ cursor: "pointer" }}
                    className="dropdown-item ai-icon"
                    onClick={() => {
                      dispatch(fetchLogout());
                      navigate("/login-page");
                    }}
                  >
                    <FiLogOut />
                    <span className="ml-2">Logout </span>
                  </div>
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
