import AdviceReportingPage from "@pages/reporting/advice/advice.page";
import CommitReportingPage from "@pages/reporting/commit/commit.page";
import InquiryReportingPage from "@pages/reporting/inquiry/inquiry.page";
import PaymentReportingPage from "@pages/reporting/payment/payment.page";


export const reportingRoute = [
  {
    path: "/reporting/inquiry/*",
    element: <InquiryReportingPage />,
    isAuth: true,
  },
  {
    path: "/reporting/payment/*",
    element: <PaymentReportingPage />,
    isAuth: true,
  },
  {
    path: "/reporting/commit/*",
    element: <CommitReportingPage />,
    isAuth: true,
  },
  // {
  //   path: "/reporting/advice/*",
  //   element: <AdviceReportingPage />,
  //   isAuth: true,
  // },
]