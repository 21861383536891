export enum Role {
  USER_SUPER_ADMIN = "Super Administrator",
  USER_ADMIN_CA = "Admin CA",
  USER_ADMIN_PARTNER = "Admin Partner",
  USER_PARTNER = "?partner",
  USER_CA = "?ca",
  USER_ADMIN_ALGO = "Admin Algo",
  USER_USER_CA = "User CA",
  USER_USER_PARTNER = "User Partner",
  USER_USER_ALGO = "User Algo"
}

export const ALL_CAN = [
  Role?.USER_SUPER_ADMIN,
  Role?.USER_ADMIN_CA,
  Role?.USER_PARTNER,
  Role?.USER_CA,
  Role?.USER_ADMIN_ALGO,
];

export const ADMIN_ROLE = [Role?.USER_ADMIN_CA, Role?.USER_ADMIN_PARTNER, Role?.USER_ADMIN_ALGO];
export const SUPER_ROLE = [Role?.USER_SUPER_ADMIN, Role?.USER_ADMIN_ALGO];
export const USER_ROLE = [Role?.USER_ADMIN_CA, Role?.USER_ADMIN_PARTNER];

export const STATUS_ACTIVE = "active";
export const STATUS_INACTIVE = "inactive";
export const STATUS_PENDING = "pending";

export const MONITORING_CA = "Monitoring CA";
export const MONITORING_PARTNER = "Monitoring Partner";

export const REPORTING_CA = "Reporting CA";
export const REPORTING_PARTNER = "Reporting Partner";

export const MONITORING = "monitoring";
export const REPORTING = "reporting";

export const CA = "ca";
export const BILLER = "biller";

export enum Transaction {
  INQUIRY = "inquiry",
  PAYMENT = "payment",
  COMMIT = "commit"
}