export const body = document.querySelector("body");
body?.setAttribute("data-typography", "poppins");
body?.setAttribute("data-theme-version", "light");
body?.setAttribute("data-layout", "vertical");
body?.setAttribute("data-nav-headerbg", "color_1");
body?.setAttribute("data-headerbg", "color_1");
body?.setAttribute("data-sidebar-style", "overlay");
body?.setAttribute("data-sibebarbg", "color_1");
body?.setAttribute("data-primary", "color_1");
body?.setAttribute("data-sidebar-position", "fixed");
body?.setAttribute("data-header-position", "fixed");
body?.setAttribute("data-container", "wide");
body?.setAttribute("direction", "ltr");

export const getPagePath = (width: number) => {
  width >= 768 && width < 1300
    ? body?.setAttribute("data-sidebar-style", "mini")
    : width <= 768
    ? body?.setAttribute("data-sidebar-style", "overlay")
    : body?.setAttribute("data-sidebar-style", "full");

  let path: string | string[] = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  return path.split("-").includes("page");
};
