import { PaymentComponent } from '@components/module/payment/payment.component';
import UnauthorizedPage from '@pages/404.page';
import { BILLER, CA, REPORTING, REPORTING_CA, REPORTING_PARTNER, Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import React from 'react'
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

const PaymentReportingPage = () => {
  const { auth } = useSelector(getAuthData);
  const location = useLocation();
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />;

  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
      case Role?.USER_ADMIN_ALGO:
        if (location.search === Role?.USER_CA) {
          renderPage = <PaymentComponent auth={auth} typeOrg={CA} module={REPORTING} />
        } else {
          renderPage = <PaymentComponent auth={auth} typeOrg={BILLER} module={REPORTING} />
        }
        break;
      case Role?.USER_ADMIN_CA:
        renderPage = <PaymentComponent auth={auth} typeOrg={CA} module={REPORTING} />
        break;
      case Role?.USER_ADMIN_PARTNER:
        renderPage = <PaymentComponent auth={auth} typeOrg={BILLER} module={REPORTING} />
        break;
      default:
        break;
    }
  }
  return (<>{renderPage}</>)
}

export default PaymentReportingPage;