import { Button } from '@components/common';
import { alertMessage } from '@components/common/alert/alert.component';
import { createApproveRequest } from '@shared/api';
import { mappingRole } from '@shared/functions';
import { getRoleList } from '@store/entities/role';
import { getUserRequest } from '@store/entities/user';
import React, { useEffect, useState } from 'react'
import { Card, Col, Modal, Row } from 'react-bootstrap'
import Table from 'react-data-table-component';
import { BsCheckLg } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { columnRequestUser } from '../data-table/data-table.component';

export const ListUserRequestComponent = (props: any) => {
  const { handlePerRowsChange, handlePageChange } = props;
  const { roleList } = useSelector(getRoleList);
  const { userRequest } = useSelector(getUserRequest);
  const [selectedRows, setSelectedRows] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [column, setColumn] = useState<any>([]);
  const [selectRoleID, setSelectRoleID] = useState<any>();
  const [resp, setResp] = useState({ message: "", status: 0 });

  const optionRole = mappingRole(roleList);

  useEffect(() => {
    const actionColumn = [
      {
        name: "Action",
        button: true,
        cell: (row: any) => (
          <Button className="btn-success btn-circle shadow btn-xs sharp p-0" onClick={() => { setShowModal(!showModal); setSelectedRows(row) }}>
            <BsCheckLg />
          </Button>
        ),
      },
    ]
    setColumn([...columnRequestUser, ...actionColumn]);
  }, [showModal]);

  const handleSubmit = async () => {
    const body = {
      id: selectedRows?.id,
      role_id: [parseInt(selectRoleID)],
    }
    const res = await createApproveRequest(body);
    if (res.status === 200) {
      setResp({ message: res.data.message, status: res.status });
    } else {
      setResp({ message: res.data.message, status: res.status });
    }
  }

  const handleOnHide = () => {
    setShowModal(!showModal); 
    setResp({ message: "", status: 0 });
  }

  return (
    <>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <h2>List User Request</h2>
            </Card.Header>
            <Card.Body>
              <Table
                data={userRequest}
                columns={column}
                pagination
                paginationServer
                paginationTotalRows={userRequest?.length}
                onChangeRowsPerPage={handlePerRowsChange}
                onChangePage={handlePageChange}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={() => handleOnHide()}
        centered
      >
        <Modal.Header>
          <h3>Approval Confirmation!</h3>
        </Modal.Header>
        <Modal.Body>
          <h4 className="mt-4">Are you sure ?</h4>
          <label>Role</label>
          <select className="form-control" onChange={(e: any) => setSelectRoleID(e.target.value)}>
            <option value="">Select Role</option>
            {optionRole?.map((option: any) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          <h4 className="mt-4">This Email {selectedRows?.email} will be approve</h4>
          {resp.message &&
            alertMessage({ status: resp.status, message: resp.message })
          }
        </Modal.Body>
        <Modal.Footer>
          <Button mode="danger" onClick={() => handleOnHide()}>Close</Button>
          <Button mode="success" onClick={() => handleSubmit()}>Approve</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}