import { api_url } from "@shared/constants";
import { assemblyParameters } from "@shared/functions";
import axios from "axios";
const url = process.env.REACT_APP_API_URL;
const tokenLocal = localStorage.getItem("AID-T");
const token = tokenLocal ? JSON.parse(atob(tokenLocal)) : "";

export const api = axios.create({
  baseURL: url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  },
});

export const fetchTransaction = async (data: any) => {
  const { module, type, moduleType, page, limit, ...rest } = data;
  const params = {
    ...rest,
    page: page ? page : 1,
    limit: limit ? limit : 10
  }
  const paramQuery = assemblyParameters(params);
  try {
    const res = await api.get(`${api_url}/v1/${module}/${type}/${moduleType}?${paramQuery}`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const putChangePassword = async (data: any) => {
  try {
    const res = await api.put(`${api_url}/v1/users/change-password`, data);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const downloadCSV = async (data: any) => {
  const { module, type_org, ...rest } = data;
  const paramQuery = assemblyParameters(rest);
  try {
    const res = await api.get(`${api_url}/v1/reporting/${type_org}/${module}/download?${paramQuery}`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const createRequest = async (data: any) => {
  try {
    const res = await api.post(`${api_url}/v1/users/requests`, data);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const createApproveRequest = async (data: any) => {
  const { id, ...rest } = data;
  try {
    const res = await api.post(`${api_url}/v1/users/requests/${id}/approve`, rest);
    return res;
  } catch (error: any) {
    return error.response;
  }
}

export const fetchProductList = async (params: any) => {
  try {
    const paramQuery = assemblyParameters(params);
    const res = await api.get(`${api_url}/v1/users/products/list?${paramQuery}`);
    return res;
  } catch (error: any) {
    return error.response;
  }
}