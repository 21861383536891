import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { api } from "@shared/api";
import { api_url, RES_STATUS } from "@shared/constants";
import { setPending, setRejected } from "@shared/functions";

export const fetchAllRole = createAsyncThunk(
  "role/FETCH_ALL",
  async (_, { rejectWithValue }) => {
    try {
      const res = await api.get(`${api_url}/v1/roles`);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
);

export const fetchRoleById = createAsyncThunk(
  "role/FETCH_BY_ID",
  async (id: any, { rejectWithValue }) => {
    try {
      const res = await api.get(`${api_url}/v1/roles/${id}`);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
)

const slice = createSlice({
  name: "role",
  initialState: {
    roleList: [],
    roleDetail: {},
    status: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    //FETCH_ALL
    builder.addCase(fetchAllRole.pending, (state) => setPending(state));
    builder.addCase(fetchAllRole.fulfilled, (state, { payload }: any) => setRoleList(state, payload));
    builder.addCase(fetchAllRole.rejected, (state, action) => setRejected(state, action));
    //FETCH_BY_ID
    builder.addCase(fetchRoleById.pending, (state) => setPending(state));
    builder.addCase(fetchRoleById.fulfilled, (state, { payload }: any) => setRoleDetail(state, payload));
    builder.addCase(fetchRoleById.rejected, (state, action) => setRejected(state, action));
  }
})

export default slice.reducer;

export const getRoleList = createSelector(
  (state: any) => ({ roleList: state.entities.role.roleList }),
  state => state
)

const setRoleList = (state: any, payload: any) => {
  state.roleList = payload;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
};

const setRoleDetail = (state: any, payload: any) => {
  state.roleDetail = payload;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
}