import { UserDetailComponent } from '@components/module/user/list/user-detail.component';
import UnauthorizedPage from '@pages/404.page';
import { Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import { fetchAllRole } from '@store/entities/role';
import { fetchUserById } from '@store/entities/user';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

const UserDetailPage = () => {
  const { auth } = useSelector(getAuthData);
  const role = auth?.UserRole[0]?.name;
  const [isLoading, setIsLoading] = useState(false);
  let renderPage = <UnauthorizedPage />;
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserById(id));
    dispatch(fetchAllRole());
    setIsLoading(false);
  }, [isLoading]);

  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
        renderPage = <UserDetailComponent userId={id} auth={auth} onLoading={() => setIsLoading(true)} />
        break;
      default:
        break;
    }
  }
  return <>{renderPage}</>
}

export default UserDetailPage