import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@components/common';

export const columnsUser = [
  {
    name: "Email",
    selector: (row: any) => row?.email,
    sortable: true,
  },
  {
    name: "Role",
    selector: (row: any) => row?.roles.map((el: any, i: number) => el.name + (i + 1 === row.roles.length ? " " : ", ")),
    sortable: true,
  },
  {
    name: "Status",
    selector: (row: any) => row?.status,
    sortable: true,
    maxWidth: "10px"
  },
  {
    name: "",
    cell: (row: any) => (
      <>
        <Link to={`/user/detail/${row.id}`}>
          <Button mode="secondary">
            More
          </Button>
        </Link>
      </>
    ),
    maxWidth: "10px"
  }
];

export const columnRequestUser = [
  {
    name: "Id",
    selector: (row: any) => row?.id,
    sortable: true,
  },
  {
    name: "Email",
    selector: (row: any) => row?.email,
    sortable: true,
  },
  {
    name: "Requested By",
    selector: (row: any) => row?.requested_by,
    sortable: true,
  },
  {
    name: "Created Time",
    selector: (row: any) => row?.created_time,
    sortable: true,
  },
  {
    name: "Updated Time",
    selector: (row: any) => row?.updated_time,
    sortable: true,
  },
]

export const columnDetailUser = [
  {
    name: "Product Code",
    selector: (row: any) => row?.product_code,
    sortable: true,
  },
  {
    name: "Product Name",
    selector: (row: any) => row?.product_name,
    sortable: true,
  },
  {
    name: "Product Id",
    selector: (row: any) => row?.product_id,
    sortable: true,
  },
  {
    name: "Provider Code",
    selector: (row: any) => row?.provider_code,
    sortable: true,
  },
  {
    name: "Provider Name",
    selector: (row: any) => row?.provider_name,
    sortable: true,
  },
  {
    name: "Provider Id",
    selector: (row: any) => row?.provider_id,
    sortable: true,
  },
]