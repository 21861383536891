import { LoginLayout } from '@components/layouts/login.layout';
import React from 'react'
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const UnauthorizedPage = () => {
  return (
    <>
      <LoginLayout>
        <h1>404 Not Found</h1>
        <Link to="/">
          Go Back
        </Link>
      </LoginLayout>
    </>

  )
}

export default UnauthorizedPage;
