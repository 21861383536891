import { IMenu } from "../menu.constant";

export const navUserCA: IMenu[] = [
  {
    url: "/",
    title: "Home",
    module: "all",
    icon: "HiOutlineHome",
  },
  {
    url: "/monitoring",
    title: "Monitoring",
    module: "all",
    icon: "MdMonitor",
    subMenus: [
      {
        url: "/monitoring/inquiry?ca",
        title: "Inquiry CA",
        module: "all"
      },
      {
        url: "/monitoring/payment?ca",
        title: "Payment CA",
        module: "all"
      },
      {
        url: "/monitoring/commit?ca",
        title: "Commit CA",
        module: "all",
      },
    ]
  },
  {
    url: "/reporting",
    title: "Reporting",
    module: "all",
    icon: "FaClipboardList",
    subMenus: [
      {
        url: "/reporting/inquiry?ca",
        title: "Inquiry CA",
        module: "all",
      },
      {
        url: "/reporting/payment?ca",
        title: "Payment CA",
        module: "all"
      },
      {
        url: "/reporting/commit?ca",
        title: "Commit CA",
        module: "all"
      },
    ]
  },
]