import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import Main from './routers';
import { Provider } from 'react-redux';
import { configureAppStore } from '@store/configureStore';

const store = configureAppStore();

ReactDOM.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18next}>
      <Main />,
    </I18nextProvider>
  </Provider>,
  document.getElementById('root')
);
