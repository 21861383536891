import UserDetailPage from '@pages/user/user-detail.page'
import UserListPage from '@pages/user/user-list.page'
import ListUserRequestPage from '@pages/user/user-request-list.page'
import UserRequestPage from '@pages/user/user-request.page'
import React from 'react'

export const userRoute = [
  {
    path:"/user/view",
    element: <UserListPage />,
    isAuth: true
  },
  {
    path:"/user/user-request",
    element: <UserRequestPage />,
    isAuth: true
  },
  {
    path:"/user/user-request/list",
    element: <ListUserRequestPage />,
    isAuth: true
  },
  {
    path:"/user/detail/:id",
    element: <UserDetailPage />,
    isAuth: true
  }
]