import InquiryMonitoringPage from "@pages/monitor/inquiry/inquiry.page";
import CommitMonitoringPage from "@pages/monitor/commit/commit.page";
import PaymentMonitoringPage from "@pages/monitor/payment/payment.page";
import CicoMonitoringPage from "@pages/monitor/cico/cico.page";
import AdviceMonitoringPage from "@pages/monitor/advice/advice.page";

export const monitoringRoute = [
  {
    path: "/monitoring/inquiry/*",
    element: <InquiryMonitoringPage />,
    isAuth: true,
  },
  {
    path: "/monitoring/commit/*",
    element: <CommitMonitoringPage />,
    isAuth: true,
  },
  {
    path: "/monitoring/payment/*",
    element: <PaymentMonitoringPage />,
    isAuth: true,
  },
  // {
  //   path: "/monitoring/advice/*",
  //   element: <AdviceMonitoringPage />,
  //   isAuth: true,
  // },
  // {
  //   path: "/monitoring/cico",
  //   element: <CicoMonitoringPage />,
  //   isAuth: true,
  // }
]