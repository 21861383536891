import { Role } from "@shared/constants";
import { optionFailed, optionsPending, optionSuccess } from "@shared/constants/form/monitoring.form.constant";

export const getTitlePage = () => {
  let path = window.location.pathname.split("/");
  let name = path[path.length - 1].split("-");
  let filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
  return filterName.join(" ").length === 0 ? "Dashboard" : filterName.join(" ");
};

export const assemblyRole = (role: string) => {
  if (role) {
    switch (role) {
      case Role.USER_SUPER_ADMIN:
        return "Super Admin";
      case Role.USER_ADMIN_ALGO:
        return "Admin Algo";
      case Role.USER_ADMIN_PARTNER:
        return "Admin Partner";
      case Role.USER_ADMIN_CA:
        return "Admin Collection Agent";
      default:
        return "-";
    }
  }
};

export const getFirstWord = (str: string) => {
  if (str) return str.split(" ")[0];
  return;
};

export const assemblyParameters = (params: any) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const mappingFormStatus = (e: any, form: any) => {
  let newFormData;
  switch (e.state) {
    case "PENDING":
      newFormData = Object.assign(form, { options: optionsPending });
      break;
    case "SUCCESS":
      newFormData = Object.assign(form, { options: optionSuccess });
      break;
    case "FAILED":
      newFormData = Object.assign(form, { options: optionFailed });
      break;
    case "":
      newFormData = Object.assign(form, { options: [] });
      break;
    default:
      break;
  }
  return newFormData;
}

export const mappingFormRole = (array: any, roleList: any) => {
  const data = array.map((el: any) => {
    if (el.name === "role_id") {
      return { ...el, options: mappingRole(roleList) }
    }
    else {
      return el;
    }
  });

  return data;
}

export const mappingDefaultStatusFromState = (state: any) => {
  let newState;
  switch (state) {
    case "SUCCESS":
      newState = "TRANSACTED";
      break;
    case "FAILED":
      newState = "FAILED";
      break;
    case "PENDING":
      newState = "TO_COMMIT";
      break;
    case "":
      newState = "";
      break;
    default:
      break;
  }
  return newState;
}

export const mappingRole = (arrayList: any) =>
  arrayList.reduce((acc: any, item: any) => {
    acc.push({
      label: item.name,
      value: item.id,
    });
    return acc;
  }, []);

export const removeEmptyObject = (e: any) =>
  Object.entries(e).reduce((a: any, [k, v]) => (!v ? a : ((a[k] = v), a)), {});