import { createAsyncThunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { api } from "@shared/api";
import { api_url, RES_STATUS } from "@shared/constants";
import { setPending, setRejected } from "@shared/functions";
import { ILogin } from "@shared/interface";

export const fetchAllPartner = createAsyncThunk(
  "partner/FETCH_ALL",
  async (params:any, { rejectWithValue }) => {
    try {
      const res = await api.get(`${api_url}/partner?${params}`);
      return res.data.data;
    } catch (error: any) {
      return rejectWithValue({ error: error.response.data.message });
    }
  }
);

const slice = createSlice({
  name: "partner",
  initialState: {
    partnerList: [],
    partnerDetail: {},
    status: "idle",
    error: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllPartner.pending, (state) => setPending(state));
    builder.addCase(fetchAllPartner.fulfilled, (state, { payload}:any) => setPartnerList(state, payload));
    builder.addCase(fetchAllPartner.rejected, (state, action) => setRejected(state, action));
  }
})

export default slice.reducer;

export const getPartnerList = createSelector(
  (state: any) => ({
    partnerList: state.entities.partnerList,
  }),
  state => state
)

const setPartnerList = (state: any, payload: any) => {
  state.partnerList = payload;
  state.status = RES_STATUS.SUCCEEDED;
  state.error = "";
};