import { CommitComponent } from '@components/module/commit/commit.component';
import UnauthorizedPage from '@pages/404.page';
import { BILLER, CA, REPORTING, REPORTING_CA, Role } from '@shared/constants';
import { getAuthData } from '@store/entities/auth';
import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';

const CommitReportingPage = () => {
  const { auth } = useSelector(getAuthData);
  const location = useLocation();
  const role = auth?.UserRole[0]?.name;
  let renderPage = <UnauthorizedPage />

  if (auth) {
    switch (role) {
      case Role?.USER_SUPER_ADMIN:
      case Role?.USER_ADMIN_ALGO:
        if (location.search === Role?.USER_CA) {
          renderPage = <CommitComponent auth={auth} typeOrg={CA} module={REPORTING} />
        } else {
          renderPage = <CommitComponent auth={auth} typeOrg={BILLER} module={REPORTING} />
        }
        break;
      case Role?.USER_ADMIN_CA:
        renderPage = <CommitComponent auth={auth} typeOrg={CA} module={REPORTING} />
        break;
      case Role?.USER_ADMIN_PARTNER:
        renderPage = <CommitComponent auth={auth} typeOrg={BILLER} module={REPORTING} />
        break;
      default:
        break;
    }
  }

  return (<>{renderPage}</>)
}
export default CommitReportingPage;